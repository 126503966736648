// import React, { useState, useEffect } from "react";
// import axios from "axios";

// // TypeScript interfaces
// interface Transaction {
//   id: string;
//   transaction_id: string;
//   user_name: string;
//   user_id: string;
//   game_title?: string;
//   transaction_type: string;
//   amount: number;
//   currency_code: string;
//   transaction_timestamp: string;
// }

// interface PaginationState {
//   totalCount: number;
//   totalPages: number;
//   currentPage: number;
//   limit: number;
// }

// interface TransactionResponse {
//   transactions: Transaction[];
//   pagination: PaginationState;
// }

// const History: React.FC = () => {
//   const [transactions, setTransactions] = useState<Transaction[]>([]);
//   const [searchTerm, setSearchTerm] = useState<string>('');
//   const [pagination, setPagination] = useState<PaginationState>({
//     totalCount: 0,
//     totalPages: 0,
//     currentPage: 1,
//     limit: 10 // Fixed to 10 items per page
//   });
//   const [loading, setLoading] = useState<boolean>(false);
//   const [userId, setUserId] = useState<string | null>(null);

//   useEffect(() => {
//     fetchTransactionData();
//   }, [pagination.currentPage, userId]);

//   // Debounced search effect
//   useEffect(() => {
//     const timer = setTimeout(() => {
//       fetchTransactionData();
//     }, 500);

//     return () => clearTimeout(timer);
//   }, [searchTerm]);

//   const fetchTransactionData = async (): Promise<void> => {
//     setLoading(true);
//     try {
//       let url = `https://api.skyla.game/betHistory/transactions?page=${pagination.currentPage}&limit=10`;
      
//       if (searchTerm) {
//         url += `&search=${encodeURIComponent(searchTerm)}`;
//       }

//       if (userId) {
//         url = `https://api.skyla.game/betHistory/transactions/user/${userId}?page=${pagination.currentPage}&limit=10`;
//         if (searchTerm) {
//           url += `&search=${encodeURIComponent(searchTerm)}`;
//         }
//       }

//       const response = await axios.get<TransactionResponse>(url);
//       setTransactions(response.data.transactions);
//       setPagination(response.data.pagination);
//     } catch (error) {
//       console.error('Failed to fetch transaction data:', error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
//     setSearchTerm(event.target.value);
//   };

//   const handleUserFilterChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
//     setUserId(event.target.value || null);
//   };

//   const handlePageChange = (newPage: number): void => {
//     setPagination(prev => ({ ...prev, currentPage: newPage }));
//   };

//   const handleClearFilters = (): void => {
//     setSearchTerm('');
//     setUserId(null);
//     setPagination(prev => ({ ...prev, currentPage: 1 }));
//   };

//   // Format timestamp to readable date & time
//   const formatDate = (timestamp: string): string => {
//     return new Date(timestamp).toLocaleString();
//   };

//   // Format amount with color depending on positive/negative value
//   const formatAmount = (amount: number): JSX.Element => {
//     const formattedAmount = `${amount > 0 ? '+' : ''}${parseFloat(amount.toString()).toFixed(2)}`;
//     const colorClass = amount > 0 ? 'text-green-600' : amount < 0 ? 'text-red-600' : '';
//     return <span className={colorClass}>{formattedAmount}</span>;
//   };

//   // Simplified pagination with just prev/next buttons and page number
//   const renderPagination = (): JSX.Element => {
//     return (
//       <div className="flex items-center justify-center mt-6">
//         <button 
//           onClick={() => handlePageChange(pagination.currentPage - 1)}
//           disabled={pagination.currentPage === 1}
//           className="px-4 py-2 mr-4 bg-gray-100 rounded border hover:bg-gray-200 disabled:opacity-50"
//           aria-label="Previous page"
//         >
//           Previous
//         </button>
        
//         <div className="text-center px-4 py-2 bg-gray-50 rounded border min-w-[80px]">
//           {pagination.currentPage} / {pagination.totalPages}
//         </div>
        
//         <button 
//           onClick={() => handlePageChange(pagination.currentPage + 1)}
//           disabled={pagination.currentPage === pagination.totalPages}
//           className="px-4 py-2 ml-4 bg-gray-100 rounded border hover:bg-gray-200 disabled:opacity-50"
//           aria-label="Next page"
//         >
//           Next
//         </button>
//       </div>
//     );
//   };

//   // Card list view (not grid)
//   const renderCardListView = (): JSX.Element => (
//     <div className="space-y-4">
//       {transactions.map((transaction) => (
//         <div key={transaction.id} className="bg-white rounded-lg shadow-md overflow-hidden border border-gray-200 hover:shadow-lg transition-shadow duration-300">
//           <div className="p-4 border-b border-gray-200 bg-gray-50">
//             <div className="font-medium">Transaction #{transaction.transaction_id}</div>
//           </div>
//           <div className="p-4">
//             <div className="flex flex-wrap -mx-2">
//               <div className="w-1/2 px-2 mb-3">
//                 <div className="text-sm text-gray-500">User</div>
//                 <div className="font-medium">{transaction.user_name}</div>
//               </div>
//               <div className="w-1/2 px-2 mb-3">
//                 <div className="text-sm text-gray-500">Game</div>
//                 <div className="font-medium">{transaction.game_title || 'Unknown'}</div>
//               </div>
//               <div className="w-1/2 px-2 mb-3">
//                 <div className="text-sm text-gray-500">Type</div>
//                 <div className="font-medium">{transaction.transaction_type}</div>
//               </div>
//               <div className="w-1/2 px-2 mb-3">
//                 <div className="text-sm text-gray-500">Date</div>
//                 <div className="font-medium">{formatDate(transaction.transaction_timestamp)}</div>
//               </div>
//             </div>
//             <div className="text-center p-2 rounded bg-gray-50 mt-2">
//               <div className="text-sm text-gray-500">Amount</div>
//               <div className="text-xl font-bold">{formatAmount(transaction.amount)} {transaction.currency_code}</div>
//             </div>
//           </div>
//         </div>
//       ))}
//     </div>
//   );

//   // Loading state
//   if (loading && transactions.length === 0) {
//     return (
//       <div className="ml-0 lg:ml-[256px] p-6">
//         <div className="flex items-center justify-center h-64">
//           <div className="text-gray-500">Loading transaction data...</div>
//         </div>
//       </div>
//     );
//   }

//   return (
//     <div className="ml-0 lg:ml-[256px] p-6">
//       <div className="bg-white rounded-lg shadow-md p-6 mb-6">
//         <h1 className="text-2xl font-bold mb-6">Bet History</h1>
        
//         {/* Filters and controls */}
//         <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
//           <div>
//             <label htmlFor="search-input" className="block text-sm font-medium text-gray-700 mb-1">Search</label>
//             <input
//               id="search-input"
//               type="text"
//               placeholder="Search by name, ID or session"
//               value={searchTerm}
//               onChange={handleSearchChange}
//               className="w-full p-2 border rounded focus:ring-blue-500 focus:border-blue-500"
//             />
//           </div>
//           <div>
//             <label htmlFor="user-id-input" className="block text-sm font-medium text-gray-700 mb-1">User ID</label>
//             <input
//               id="user-id-input"
//               type="text"
//               placeholder="Filter by User ID"
//               value={userId || ''}
//               onChange={handleUserFilterChange}
//               className="w-full p-2 border rounded focus:ring-blue-500 focus:border-blue-500"
//             />
//           </div>
//         </div>
        
//         {/* Info and clear filters */}
//         <div className="mb-4">
//           <span className="text-gray-600">
//             Showing {transactions.length} of {pagination.totalCount} transactions
//           </span>
//           {(searchTerm || userId) && (
//             <button 
//               onClick={handleClearFilters}
//               className="ml-4 text-blue-500 hover:text-blue-700"
//               aria-label="Clear filters"
//             >
//               Clear filters
//             </button>
//           )}
//         </div>
        
//         {/* No results message */}
//         {transactions.length === 0 && !loading && (
//           <div className="text-center py-10">
//             <p className="text-gray-500">No transactions found</p>
//           </div>
//         )}
        
//         {/* Transaction list */}
//         {renderCardListView()}
        
//         {/* Pagination */}
//         {pagination.totalPages > 1 && renderPagination()}
//       </div>
//     </div>
//   );
// };

// export default History;
import React, { useState, useEffect } from "react";
import axios from "axios";

// TypeScript interfaces
interface Transaction {
  id: string;
  transaction_id: string;
  user_name: string;
  user_id: string;
  game_title?: string;
  transaction_type: string;
  amount: number;
  currency_code: string;
  transaction_timestamp: string;
}

interface PaginationState {
  totalCount: number;
  totalPages: number;
  currentPage: number;
  limit: number;
}

interface TransactionResponse {
  transactions: Transaction[];
  pagination: PaginationState;
}

const History: React.FC = () => {
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [pagination, setPagination] = useState<PaginationState>({
    totalCount: 0,
    totalPages: 0,
    currentPage: 1,
    limit: 10 // Fixed to 10 items per page
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [userId, setUserId] = useState<string | null>(null);

  useEffect(() => {
    fetchTransactionData();
  }, [pagination.currentPage, userId]);

  // Debounced search effect
  useEffect(() => {
    const timer = setTimeout(() => {
      fetchTransactionData();
    }, 500);

    return () => clearTimeout(timer);
  }, [searchTerm]);

  const fetchTransactionData = async (): Promise<void> => {
    setLoading(true);
    try {
      let url = `https://api.skyla.game/betHistory/transactions?page=${pagination.currentPage}&limit=10`;
      
      if (searchTerm) {
        url += `&search=${encodeURIComponent(searchTerm)}`;
      }

      if (userId) {
        url = `https://api.skyla.game/betHistory/transactions/user/${userId}?page=${pagination.currentPage}&limit=10`;
        if (searchTerm) {
          url += `&search=${encodeURIComponent(searchTerm)}`;
        }
      }

      const response = await axios.get<TransactionResponse>(url);
      setTransactions(response.data.transactions);
      setPagination(response.data.pagination);
    } catch (error) {
      console.error('Failed to fetch transaction data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchTerm(event.target.value);
  };

  const handleUserFilterChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setUserId(event.target.value || null);
  };

  const handlePageChange = (newPage: number): void => {
    setPagination(prev => ({ ...prev, currentPage: newPage }));
  };

  const handleClearFilters = (): void => {
    setSearchTerm('');
    setUserId(null);
    setPagination(prev => ({ ...prev, currentPage: 1 }));
  };

  // Format timestamp to readable date & time
  const formatDate = (timestamp: string): string => {
    return new Date(timestamp).toLocaleString();
  };

  // Format amount with color depending on transaction type
  const formatAmount = (amount: number, type: string): JSX.Element => {
    const colorMap = {
      'WIN': 'text-green-600',
      'LOSS': 'text-red-600',
      'BET': 'text-gray-800'
    };

    const signPrefix = type === 'WIN' ? '+' : type === 'LOSS' ? '-' : '';
    const colorClass = colorMap[type as keyof typeof colorMap] || 'text-gray-800';

    return <span className={colorClass}>{signPrefix}{Math.abs(amount).toFixed(2)}</span>;
  };

  // Simplified pagination with just prev/next buttons and page number
  const renderPagination = (): JSX.Element => {
    return (
      <div className="flex items-center justify-center mt-6">
        <button 
          onClick={() => handlePageChange(pagination.currentPage - 1)}
          disabled={pagination.currentPage === 1}
          className="px-4 py-2 mr-4 bg-gray-100 rounded border hover:bg-gray-200 disabled:opacity-50"
          aria-label="Previous page"
        >
          Previous
        </button>
        
        <div className="text-center px-4 py-2 bg-gray-50 rounded border min-w-[80px]">
          {pagination.currentPage} / {pagination.totalPages}
        </div>
        
        <button 
          onClick={() => handlePageChange(pagination.currentPage + 1)}
          disabled={pagination.currentPage === pagination.totalPages}
          className="px-4 py-2 ml-4 bg-gray-100 rounded border hover:bg-gray-200 disabled:opacity-50"
          aria-label="Next page"
        >
          Next
        </button>
      </div>
    );
  };

  // Table view rendering
  const renderTableView = (): JSX.Element => (
    <div className="overflow-x-auto">
      <table className="w-full bg-white shadow-md rounded-lg overflow-hidden">
        <thead className="bg-gray-100 border-b">
          <tr>
            <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">User</th>
            <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Game</th>
            <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Type</th>
            <th className="px-4 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Amount</th>
            <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {transactions.map((transaction) => (
            <tr key={transaction.id} className="hover:bg-gray-50 transition-colors">
              <td className="px-4 py-3 whitespace-nowrap">{transaction.user_name}</td>
              <td className="px-4 py-3 whitespace-nowrap">{transaction.game_title || 'Unknown'}</td>
              <td className="px-4 py-3 whitespace-nowrap">{transaction.transaction_type}</td>
              <td className="px-4 py-3 text-right whitespace-nowrap">
                {formatAmount(transaction.amount, transaction.transaction_type)}
              </td>
              <td className="px-4 py-3 whitespace-nowrap">{formatDate(transaction.transaction_timestamp)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  // Loading state
  if (loading && transactions.length === 0) {
    return (
      <div className="ml-0 lg:ml-[256px] p-6">
        <div className="flex items-center justify-center h-64">
          <div className="text-gray-500">Loading transaction data...</div>
        </div>
      </div>
    );
  }

  return (
    <div className="ml-0 lg:ml-[256px] p-6">
      <div className="bg-white rounded-lg shadow-md p-6 mb-6">
        <h1 className="text-2xl font-bold mb-6">Bet History</h1>
        
        {/* Filters and controls */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
          <div>
            <label htmlFor="search-input" className="block text-sm font-medium text-gray-700 mb-1">Search</label>
            <input
              id="search-input"
              type="text"
              placeholder="Search by name, ID or session"
              value={searchTerm}
              onChange={handleSearchChange}
              className="w-full p-2 border rounded focus:ring-blue-500 focus:border-blue-500"
            />
          </div>
          <div>
            <label htmlFor="user-id-input" className="block text-sm font-medium text-gray-700 mb-1">User ID</label>
            <input
              id="user-id-input"
              type="text"
              placeholder="Filter by User ID"
              value={userId || ''}
              onChange={handleUserFilterChange}
              className="w-full p-2 border rounded focus:ring-blue-500 focus:border-blue-500"
            />
          </div>
        </div>
        
        {/* Info and clear filters */}
        <div className="mb-4">
          <span className="text-gray-600">
            Showing {transactions.length} of {pagination.totalCount} transactions
          </span>
          {(searchTerm || userId) && (
            <button 
              onClick={handleClearFilters}
              className="ml-4 text-blue-500 hover:text-blue-700"
              aria-label="Clear filters"
            >
              Clear filters
            </button>
          )}
        </div>
        
        {/* No results message */}
        {transactions.length === 0 && !loading && (
          <div className="text-center py-10">
            <p className="text-gray-500">No transactions found</p>
          </div>
        )}
        
        {/* Transaction table */}
        {renderTableView()}
        
        {/* Pagination */}
        {pagination.totalPages > 1 && renderPagination()}
      </div>
    </div>
  );
};

export default History;