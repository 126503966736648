// import React, { useEffect, useRef, useState } from "react";
// import { NavLink, useNavigate } from "react-router-dom";
// import { Menu, X, LayoutDashboard, Users, CreditCard, Wallet, History, LogOut, ArrowUpCircle, ArrowDownCircle, Percent, Bell, User, Gamepad, EggFriedIcon, PlusCircle, List, ChevronDown, ChevronRight, Link,Gift } from "lucide-react";
// import logo from '../assets/logo.png';

// interface SidebarProps {
//   isOpen: boolean;
//   toggleSidebar: () => void;
// }

// const Sidebar: React.FC<SidebarProps> = ({ isOpen, toggleSidebar }) => {
//   const navigate = useNavigate();

//   const [gamesExpanded, setGamesExpanded] = useState(false);

//   const toggleGamesExpanded = () => {
//     setGamesExpanded(!gamesExpanded);
//   };

//   const handleLogout = () => {
//     localStorage.removeItem("token");
//     localStorage.removeItem("email");
//     navigate("/login");
//     toggleSidebar()
//   };

//   const sidebarRef = useRef<HTMLDivElement>(null);

//   useEffect(() => {
//     const handleClickOutside = (event: MouseEvent) => {
//       if (isOpen && sidebarRef.current && !sidebarRef.current.contains(event.target as Node)) {
//         toggleSidebar();
//       }
//     };

//     document.addEventListener("mousedown", handleClickOutside);
//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, [isOpen, toggleSidebar]);

//   return (
//     <>
//       <button 
//         className="fixed top-4 left-4 z-20 text-gray-700 lg:hidden"
//         onClick={toggleSidebar}
//       >
//         {isOpen ? <X className="text-white" size={24} /> : <Menu className="text-white"  size={24} />}
//       </button>

//       <div ref={sidebarRef} className={`w-64 min-h-screen bg-[#2B3141] shadow-md px-1 fixed top-0 left-0 z-[999] transition-all duration-300 ease-in-out ${isOpen ? 'translate-x-0' : '-translate-x-full'} lg:translate-x-0`}>
//         <div className=" flex items-center">
//           <img src={logo} alt="Logo" className="h-auto w-[120px] mr-2" />
//         </div>
//         <div className="flex-1 overflow-y-auto">
//         <nav className="text-sm px-1">
//           <div className="mb-4 mt-4">
//             <NavLink
//               to="/"
//               className={({ isActive }) =>
//                 `group flex items-center py-2 px-4 rounded-lg transition duration-200 text-white hover:text-[#646ECD] ${
//                   isActive ? "bg-blue-100 text-blue-400" : "hover:bg-gray-100"
//                 }`
//               }
//             >
//               <LayoutDashboard size={18} className="mr-3 text-white group-hover:text-[#646ECD]" />
//               Dashboard
//             </NavLink>
//             <NavLink
//               to="/users"
//               className={({ isActive }) =>
//                 `group flex items-center py-2 px-4 rounded-lg transition duration-200 text-white hover:text-[#646ECD] ${
//                   isActive ? "bg-blue-100 text-blue-400" : "hover:bg-gray-100"
//                 }`
//               }
//             >
//               <Users size={18} className="mr-3 text-white group-hover:text-[#646ECD]" />
//               Users
//             </NavLink>
//             <NavLink
//               to="/deposit"
//               onClick={toggleSidebar}
//               className={({ isActive }) =>
//                 `group flex items-center py-2 px-4 rounded-lg transition duration-200 text-white hover:text-[#646ECD] ${
//                   isActive ? "bg-blue-100 text-blue-400" : "hover:bg-gray-100"
//                 }`
//               }
//             >
//               <ArrowUpCircle size={18} className="mr-3 text-white group-hover:text-[#646ECD]" />
//               Deposit
//             </NavLink>
//             <NavLink
//               onClick={toggleSidebar}
//               to="/withdraw"
//               className={({ isActive }) =>
//                 `group flex items-center py-2 px-4 rounded-lg transition duration-200 text-white hover:text-[#646ECD] ${
//                   isActive ? "bg-blue-100 text-blue-400" : "hover:bg-gray-100"
//                 }`
//               }
//             >
//               <ArrowDownCircle size={18} className="mr-3 text-white group-hover:text-[#646ECD]" />
//               Withdraw
//             </NavLink>
//             {/* <div>
//               <button
//                 onClick={toggleGamesExpanded}
//                 className={`group flex items-center justify-between w-full py-2 px-4 rounded-lg transition duration-200 text-white hover:text-[#646ECD] hover:bg-gray-100 ${
//                   gamesExpanded ? "bg-blue-100 text-blue-400" : ""
//                 }`}
//               >
//                 <div className="flex items-center">
//                   <Gamepad size={18} className="mr-3 text-white group-hover:text-[#646ECD]" />
//                   Manage Games
//                 </div>
//                 {gamesExpanded ? <ChevronDown size={18} className="group-hover:text-[#646ECD]" /> : <ChevronRight size={18} className="group-hover:text-[#646ECD]" />}
//               </button>
//               <div className={`ml-6 gap-2 overflow-hidden transition-all duration-300 ease-in-out ${
//                 gamesExpanded ? "max-h-20" : "max-h-0"
//               }`}>
//                 <NavLink
//                   onClick={toggleSidebar}
//                   to="/games"
//                   className={({ isActive }) =>
//                     `group flex items-center py-2 px-4 gap-1 rounded-lg transition duration-200 text-white hover:text-[#646ECD] ${
//                       isActive ? "bg-blue-100 text-blue-400" : "hover:bg-gray-100"
//                     }`
//                   }
//                 >
//                   <PlusCircle size={18} className="mr-3 text-white group-hover:text-[#646ECD]" />
//                   Games
//                 </NavLink>
//                 <NavLink
//                   onClick={toggleSidebar}
//                   to="win-settings"
//                   className={({ isActive }) =>
//                     `group flex items-center py-2 px-4 rounded-lg transition duration-200 text-white hover:text-[#646ECD] ${
//                       isActive ? "bg-blue-100 text-blue-400" : "hover:bg-gray-100"
//                     }`
//                   }
//                 >
//                   <List size={18} className="mr-3 text-white group-hover:text-[#646ECD]" />
//                   Win Setting
//                 </NavLink>
//               </div>
//             </div> */}
//             <NavLink
//               onClick={toggleSidebar}
//               to="/affiliate"
//               className={({ isActive }) =>
//                 `group flex items-center py-2 px-4 rounded-lg transition duration-200 text-white hover:text-[#646ECD] ${
//                   isActive ? "bg-blue-100 text-blue-400" : "hover:bg-gray-100"
//                 }`
//               }
//             >
//               <Link size={18} className="mr-3 text-white group-hover:text-[#646ECD]" />
//               Affiliate
//             </NavLink>
//             {/* <NavLink
//               onClick={toggleSidebar}
//               to="/seo-manager"
//               className={({ isActive }) =>
//                 `group flex items-center py-2 px-4 rounded-lg transition duration-200 text-white hover:text-[#646ECD] ${
//                   isActive ? "bg-blue-100 text-blue-400" : "hover:bg-gray-100"
//                 }`
//               }
//             >
//               <User size={18} className="mr-3 text-white group-hover:text-[#646ECD]" />
//               SEO Manager
//             </NavLink>
//             <NavLink
//               onClick={toggleSidebar}
//               to="/notification-settings"
//               className={({ isActive }) =>
//                 `group flex items-center py-2 px-4 rounded-lg transition duration-200 text-white hover:text-[#646ECD] ${
//                   isActive ? "bg-blue-100 text-blue-400" : "hover:bg-gray-100"
//                 }`
//               }
//             >
//               <Bell size={18} className="mr-3 text-white group-hover:text-[#646ECD]" />
//               Notification Settings
//             </NavLink> */}
//             <NavLink
//               to="/house"
//               onClick={toggleSidebar}
//               className={({ isActive }) =>
//                 `group flex items-center py-2 px-4 rounded-lg transition duration-200 text-white hover:text-[#646ECD] ${
//                   isActive ? "bg-blue-100 text-blue-400" : "hover:bg-gray-100"
//                 }`
//               }
//             >
//               <Percent size={18} className="mr-3 text-white group-hover:text-[#646ECD]" />
//               House Edge
//             </NavLink>
//             <NavLink
//               onClick={toggleSidebar}
//               to="/wallet"
//               className={({ isActive }) =>
//                 `group flex items-center py-2 px-4 rounded-lg transition duration-200 text-white hover:text-[#646ECD] ${
//                   isActive ? "bg-blue-100 text-blue-400" : "hover:bg-gray-100"
//                 }`
//               }
//             >
//               <Wallet size={18} className="mr-3 text-white group-hover:text-[#646ECD]" />
//               Wallet
//             </NavLink>
//             <NavLink
//               onClick={toggleSidebar}
//               to="/history"
//               className={({ isActive }) =>
//                 `group flex items-center py-2 px-4 rounded-lg transition duration-200 text-white hover:text-[#646ECD] ${
//                   isActive ? "bg-blue-100 text-blue-400" : "hover:bg-gray-100"
//                 }`
//               }
//             >
//               <History size={18} className="mr-3 text-white group-hover:text-[#646ECD]" />
//               History
//             </NavLink>
//             <NavLink
//               onClick={toggleSidebar}
//               to="/bonus"
//               className={({ isActive }) =>
//                 `group flex items-center py-2 px-4 rounded-lg transition duration-200 text-white hover:text-[#646ECD] ${
//                   isActive ? "bg-blue-100 text-blue-400" : "hover:bg-gray-100"
//                 }`
//               }
//             >
//               <Gift size={18} className="mr-3 text-white group-hover:text-[#646ECD]" />
//               Bonus
//             </NavLink>
//             <NavLink
//               to="/club"
//               className={({ isActive }) =>
//                 `group flex items-center py-2 px-4 rounded-lg transition duration-200 text-white hover:text-[#646ECD] ${
//                   isActive ? "bg-blue-100 text-blue-400" : "hover:bg-gray-100"
//                 }`
//               }
//             >
//               <Users size={18} className="mr-3 text-white group-hover:text-[#646ECD]" />
//               Club Membership
//             </NavLink>
//             <NavLink
//               onClick={toggleSidebar}
//               to="/siteconfig"
//               className={({ isActive }) =>
//                 `group flex items-center py-2 px-4 rounded-lg transition duration-200 text-white hover:text-[#646ECD] ${
//                   isActive ? "bg-blue-100 text-blue-400" : "hover:bg-gray-100"
//                 }`
//               }
//             >
//               <Link size={18} className="mr-3 text-white group-hover:text-[#646ECD]" />
//               Site Config
//             </NavLink>
//             <NavLink
//               onClick={toggleSidebar}
//               to="/banner"
//               className={({ isActive }) =>
//                 `group flex items-center py-2 px-4 rounded-lg transition duration-200 text-white hover:text-[#646ECD] ${
//                   isActive ? "bg-blue-100 text-blue-400" : "hover:bg-gray-100"
//                 }`
//               }
//             >
//               <Link size={18} className="mr-3 text-white group-hover:text-[#646ECD]" />
//              Asset Managment
//             </NavLink>
//             <NavLink
//               onClick={toggleSidebar}
//               to="/bankdetails"
//               className={({ isActive }) =>
//                 `group flex items-center py-2 px-4 rounded-lg transition duration-200 text-white hover:text-[#646ECD] ${
//                   isActive ? "bg-blue-100 text-blue-400" : "hover:bg-gray-100"
//                 }`
//               }
//             >
//               <Link size={18} className="mr-3 text-white group-hover:text-[#646ECD]" />
//              Bank Details
//             </NavLink>
//             <NavLink
//               onClick={toggleSidebar}
//               to="/sportsbeting"
//               className={({ isActive }) =>
//                 `group flex items-center py-2 px-4 rounded-lg transition duration-200 text-white hover:text-[#646ECD] ${
//                   isActive ? "bg-blue-100 text-blue-400" : "hover:bg-gray-100"
//                 }`
//               }
//             >
//               <Link size={18} className="mr-3 text-white group-hover:text-[#646ECD]" />
//              Sports Bets
//             </NavLink>
//             <NavLink
//               onClick={toggleSidebar}
//               to="/redeemcode"
//               className={({ isActive }) =>
//                 `group flex items-center py-2 px-4 rounded-lg transition duration-200 text-white hover:text-[#646ECD] ${
//                   isActive ? "bg-blue-100 text-blue-400" : "hover:bg-gray-100"
//                 }`
//               }
//             >
//               <Link size={18} className="mr-3 text-white group-hover:text-[#646ECD]" />
//              Redeem Code Creation
//             </NavLink>
//             <NavLink
//               onClick={toggleSidebar}
//               to="/exchangerate"
//               className={({ isActive }) =>
//                 `group flex items-center py-2 px-4 rounded-lg transition duration-200 text-white hover:text-[#646ECD] ${
//                   isActive ? "bg-blue-100 text-blue-400" : "hover:bg-gray-100"
//                 }`
//               }
//             >
//               <Link size={18} className="mr-3 text-white group-hover:text-[#646ECD]" />
//              Exchange Rate
//             </NavLink>
//             <NavLink
//               onClick={toggleSidebar}
//               to="/notification"
//               className={({ isActive }) =>
//                 `group flex items-center py-2 px-4 rounded-lg transition duration-200 text-white hover:text-[#646ECD] ${
//                   isActive ? "bg-blue-100 text-blue-400" : "hover:bg-gray-100"
//                 }`
//               }
//             >
//               <Link size={18} className="mr-3 text-white group-hover:text-[#646ECD]" />
//             User Notification
//             </NavLink>
//             <NavLink
//               onClick={toggleSidebar}
//               to="/swaphistory"
//               className={({ isActive }) =>
//                 `group flex items-center py-2 px-4 rounded-lg transition duration-200 text-white hover:text-[#646ECD] ${
//                   isActive ? "bg-blue-100 text-blue-400" : "hover:bg-gray-100"
//                 }`
//               }
//             >
//               <Link size={18} className="mr-3 text-white group-hover:text-[#646ECD]" />
//            Swap History
//             </NavLink>
//           </div>
//         </nav>
// </div>
// <div className="p-4 border-t border-gray-700">
//         <button
//           onClick={handleLogout}
//           className="flex items-center w-full py-2 px-4 text-white hover:text-[#646ECD] hover:bg-gray-100 rounded-lg transition duration-200 text-left mt-auto absolute bottom-4"
//         >
//           <LogOut size={18} className="mr-3" />
//           Log out
//         </button>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Sidebar;

import React, { useEffect, useRef, useState } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { Menu, X, LayoutDashboard, Users, CreditCard, Wallet, History, LogOut, ArrowUpCircle, 
  ArrowDownCircle, Percent, Bell, User, Gamepad, Link, Gift, Settings, ChevronsRight, Home } from "lucide-react";
import logo from '../assets/logo.png';

interface SidebarProps {
  isOpen: boolean;
  toggleSidebar: () => void;
}

// Define menu item groups for better organization
interface MenuItem {
  path: string;
  label: string;
  icon: React.ReactNode;
}

interface MenuGroup {
  title: string;
  items: MenuItem[];
}

const Sidebar: React.FC<SidebarProps> = ({ isOpen, toggleSidebar }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [collapsed, setCollapsed] = useState<Record<string, boolean>>({});
  const sidebarRef = useRef<HTMLDivElement>(null);

  // Organize sidebar items into logical groups
  const menuGroups: MenuGroup[] = [
    {
      title: "Main",
      items: [
        { path: "/", label: "Dashboard", icon: <LayoutDashboard size={18} /> },
        { path: "/users", label: "Users", icon: <Users size={18} /> }
      ]
    },
    {
      title: "Transactions",
      items: [
        { path: "/deposit", label: "Deposit", icon: <ArrowUpCircle size={18} /> },
        { path: "/withdraw", label: "Withdraw", icon: <ArrowDownCircle size={18} /> },
        { path: "/wallet", label: "Wallet", icon: <Wallet size={18} /> },
        { path: "/history", label: "Bet History", icon: <History size={18} /> },
        { path: "/swaphistory", label: "Swap History", icon: <CreditCard size={18} /> }
      ]
    },
    {
      title: "Gaming",
      items: [
        { path: "/house", label: "House Edge", icon: <Percent size={18} /> },
        { path: "/sportsbeting", label: "Sports Bets", icon: <Gamepad size={18} /> },
        { path: "/club", label: "Club Membership", icon: <Users size={18} /> }
      ]
    },
    {
      title: "Marketing",
      items: [
        { path: "/affiliate", label: "Affiliate", icon: <Link size={18} /> },
        { path: "/bonus", label: "Bonus", icon: <Gift size={18} /> },
        { path: "/redeemcode", label: "Redeem Codes", icon: <Gift size={18} /> },
        { path: "/notification", label: "User Notification", icon: <Bell size={18} /> }
      ]
    },
    {
      title: "Settings",
      items: [
        { path: "/siteconfig", label: "Site Config", icon: <Settings size={18} /> },
        { path: "/banner", label: "Asset Management", icon: <CreditCard size={18} /> },
        { path: "/bankdetails", label: "Bank Details", icon: <CreditCard size={18} /> },
        { path: "/exchangerate", label: "Exchange Rate", icon: <CreditCard size={18} /> }
      ]
    }
  ];

  const toggleGroupCollapse = (title: string) => {
    setCollapsed(prev => ({
      ...prev,
      [title]: !prev[title]
    }));
  };

  useEffect(() => {
    // Find which group contains the current path and expand it
    menuGroups.forEach(group => {
      const hasActivePath = group.items.some(item => item.path === location.pathname);
      if (hasActivePath) {
        setCollapsed(prev => ({
          ...prev,
          [group.title]: false // Not collapsed
        }));
      }
    });
  }, [location.pathname]);

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("email");
    navigate("/login");
    toggleSidebar();
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (isOpen && sidebarRef.current && !sidebarRef.current.contains(event.target as Node)) {
        toggleSidebar();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, toggleSidebar]);

  return (
    <>
      <div 
        ref={sidebarRef} 
        className={`w-64 h-screen bg-[#1A2033] shadow-lg fixed top-0 left-0 z-50 transition-transform duration-300 ease-in-out ${
          isOpen ? 'translate-x-0' : '-translate-x-full'
        } lg:translate-x-0 flex flex-col border-r border-[#2D334A]`}
      >
        <div className="p-4 flex items-center justify-between border-b border-[#2D334A]">
          <div className="flex items-center">
            <img src={logo} alt="Logo" className="h-8 w-auto" />
          </div>
          <button 
            className="text-gray-400 hover:text-white lg:hidden"
            onClick={toggleSidebar}
          >
            <X size={20} />
          </button>
        </div>

        <div className="flex-1 overflow-y-auto py-2 px-2">
          {menuGroups.map((group, groupIndex) => (
            <div key={groupIndex} className="mb-3">
              <button 
                onClick={() => toggleGroupCollapse(group.title)}
                className="w-full flex items-center justify-between px-3 py-2 text-xs font-medium text-gray-400 uppercase tracking-wider"
              >
                <span>{group.title}</span>
                <ChevronsRight 
                  size={14} 
                  className={`transition-transform duration-200 ${!collapsed[group.title] ? 'rotate-90' : ''}`} 
                />
              </button>

              <div className={`space-y-1 mt-1 transition-all duration-200 ease-in-out overflow-hidden ${
                collapsed[group.title] ? 'max-h-0 opacity-0' : 'max-h-96 opacity-100'
              }`}>
                {group.items.map((item, itemIndex) => (
                  <NavLink
                    key={itemIndex}
                    to={item.path}
                    onClick={window.innerWidth < 1024 ? toggleSidebar : undefined}
                    className={({ isActive }) =>
                      `flex items-center px-3 py-2 rounded-md text-sm transition-all duration-200 ${
                        isActive 
                          ? 'bg-[#646ECD] bg-opacity-20 text-[#646ECD]' 
                          : 'text-gray-300 hover:bg-[#2B3141] hover:text-white'
                      }`
                    }
                  >
                    <span className="mr-3">{item.icon}</span>
                    <span>{item.label}</span>
                  </NavLink>
                ))}
              </div>
            </div>
          ))}
        </div>

        <div className="mt-auto p-3 border-t border-[#2D334A]">
          <button
            onClick={handleLogout}
            className="flex items-center w-full px-3 py-2 text-sm text-gray-300 hover:bg-[#2B3141] hover:text-white rounded-md transition-all duration-200"
          >
            <LogOut size={18} className="mr-3" />
            <span>Log Out</span>
          </button>
        </div>
      </div>
    </>
  );
};

export default Sidebar;