import React, { useState, useEffect } from 'react';
import axios from 'axios';

// Define interface for team object
interface Team {
  id: string | number;
  name?: string;
  owner_name?: string;
  owner_email?: string;
  owner_avatar?: string;
  owner_level?: number;
  referalCode?: string;
  createdAt?: string;
  updatedAt?: string;
}

// Define interface for team member
interface TeamMember {
  id: string | number;
  name?: string;
  avatar?: string;
  level?: number;
  games_played?: number;
}

// Define interface for user with team
interface UserWithTeam {
  id: string | number;
  name?: string;
  email?: string;
  avatar?: string;
  level?: number;
  team_name?: string;
  children?: UserWithTeam[];
}

const Referal: React.FC = () => {
  const [teams, setTeams] = useState<Team[]>([]);
  const [teamMembers, setTeamMembers] = useState<Record<string | number, TeamMember[]>>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [expandedTeam, setExpandedTeam] = useState<string | number | null>(null);
  const [activeTab, setActiveTab] = useState<'teams' | 'hierarchy'>('teams');
  const [usersWithTeams, setUsersWithTeams] = useState<UserWithTeam[]>([]);

  const API_BASE_URL = 'https://api.skyla.game/affiliateAdmin';

  useEffect(() => {
    fetchTeams();
    fetchUsersWithTeams();
  }, []);

  const fetchTeams = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${API_BASE_URL}/teams`);
      if (response.data.success) {
        setTeams(response.data.data);
      }
    } catch (error) {
      console.error('Error fetching teams:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchUsersWithTeams = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/users-with-teams`);
      if (response.data.success) {
        setUsersWithTeams(response.data.data);
      }
    } catch (error) {
      console.error('Error fetching users with teams:', error);
    }
  };

  const fetchTeamMembers = async (teamId: string | number) => {
    if (teamMembers[teamId]) return; // Don't fetch if we already have the data
    
    try {
      const response = await axios.get(`${API_BASE_URL}/teams/${teamId}/members`);
      if (response.data.success) {
        setTeamMembers(prev => ({
          ...prev,
          [teamId]: response.data.data
        }));
      }
    } catch (error) {
      console.error(`Error fetching members for team ${teamId}:`, error);
    }
  };

  const toggleTeamExpansion = (teamId: any) => {
    if (expandedTeam === teamId) {
      setExpandedTeam(null);
    } else {
      setExpandedTeam(teamId);
      fetchTeamMembers(teamId);
    }
  };

  // Filter teams based on search term
  const filteredTeams = teams.filter(team => 
    team.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    team.owner_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    team.referalCode?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Function to generate a random color based on string
  const stringToColor = (string?: string): string => {
    if (!string) return '#6366f1'; // Default color if string is undefined
    
    let hash = 0;
    for (let i = 0; i < string.length; i++) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = '#';
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xFF;
      color += ('00' + value.toString(16)).substr(-2);
    }
    return color;
  };

  // Format date
  const formatDate = (dateString?: string): string => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  return (
    
    <div className="min-h-screen bg-gray-50">
      <div className="ml-0 lg:ml-[256px] p-2">
        {/* Page Header with Tabs */}
        <div className="flex flex-col space-y-6 mb-6">
          <div className="flex flex-col md:flex-row justify-between items-start md:items-center">
            <h1 className="text-2xl font-bold text-gray-800">Affiliate Management</h1>
            
            <div className="relative mt-4 md:mt-0 w-full md:w-64">
              <input
                type="text"
                placeholder="Search..."
                className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <div className="absolute left-3 top-2.5 text-gray-400">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                </svg>
              </div>
            </div>
          </div>
          
          <div className="border-b border-gray-200">
            <nav className="-mb-px flex space-x-8">
              <button
                onClick={() => setActiveTab('teams')}
                className={`py-2 px-1 border-b-2 font-medium text-sm ${
                  activeTab === 'teams'
                    ? 'border-indigo-500 text-indigo-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                }`}
              >
                Teams
              </button>
              <button
                onClick={() => setActiveTab('hierarchy')}
                className={`py-2 px-1 border-b-2 font-medium text-sm ${
                  activeTab === 'hierarchy'
                    ? 'border-indigo-500 text-indigo-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                }`}
              >
                User Hierarchy
              </button>
            </nav>
          </div>
        </div>
        
        {/* Page Content */}
        {activeTab === 'teams' ? (
          <div className="bg-white rounded-xl shadow-md overflow-hidden">
            {loading ? (
              <div className="flex justify-center items-center h-64">
                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-500"></div>
              </div>
            ) : filteredTeams.length > 0 ? (
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Team</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Owner</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Referral Code</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Created</th>
                      <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {filteredTeams.map((team) => (
                      <React.Fragment key={team.owner_name}>
                        <tr 
                          className="hover:bg-gray-50 cursor-pointer transition-colors"
                          onClick={() => toggleTeamExpansion(team.owner_name)}
                        >
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="flex items-center">
                              <div className="flex-shrink-0 h-10 w-10">
                                <div 
                                  className="h-10 w-10 rounded-full flex items-center justify-center text-white font-bold"
                                  style={{ backgroundColor: stringToColor(team.owner_name) }}
                                >
                                  {team.owner_name?.charAt(0).toUpperCase() || '?'}
                                </div>
                              </div>
                              <div className="ml-4">
                                <div className="text-sm font-medium text-gray-900">{team.owner_name}</div>
                              </div>
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="flex items-center">
                              {team.owner_avatar ? (
                                <img 
                                  src={team.owner_avatar} 
                                  alt={team.owner_name || ''} 
                                  className="h-8 w-8 rounded-full mr-3"
                                />
                              ) : (
                                <div 
                                  className="h-8 w-8 rounded-full flex items-center justify-center text-white font-bold mr-3"
                                  style={{ backgroundColor: stringToColor(team.owner_name) }}
                                >
                                  {team.owner_name?.charAt(0).toUpperCase() || '?'}
                                </div>
                              )}
                              <div>
                                <div className="text-sm font-medium text-gray-900">{team.owner_name}</div>
                                {team.owner_email && (
                                  <div className="text-xs text-gray-500">{team.owner_email}</div>
                                )}
                              </div>
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <span className="px-3 py-1 inline-flex text-xs leading-5 font-semibold rounded-full bg-indigo-100 text-indigo-800">
                              {team.referalCode}
                            </span>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {formatDate(team.createdAt)}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                            <button className="text-indigo-600 hover:text-indigo-900">
                              {expandedTeam === team.owner_name ? (
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                  <path fillRule="evenodd" d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z" clipRule="evenodd" />
                                </svg>
                              ) : (
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                  <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                                </svg>
                              )}
                            </button>
                          </td>
                        </tr>
                        
                        {/* Expanded Row */}
                        {expandedTeam === team.owner_name && (
                          <tr>
                            <td colSpan={5} className="px-6 py-4 bg-gray-50">
                              <div className="animate-fadeIn">
                                <h3 className="text-lg font-medium text-gray-900 mb-4">Team Details</h3>
                                
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
                                  {/* Team Info Card */}
                                  <div className="bg-white p-4 rounded-lg border border-gray-200">
                                    <h4 className="text-sm font-medium text-gray-500 mb-3 flex items-center">
                                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-1 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                      </svg>
                                      Team Information
                                    </h4>
                                    <div className="space-y-3">
                                      <div className="flex justify-between">
                                        <span className="text-sm text-gray-600">Team ID:</span>
                                        <span className="text-sm font-medium">{team.owner_name}</span>
                                      </div>
                                      <div className="flex justify-between">
                                        <span className="text-sm text-gray-600">Owner Level:</span>
                                        <span className="text-sm font-medium">
                                          {team.owner_level ? (
                                            <span className="px-2 py-1 text-xs rounded bg-green-100 text-green-800">
                                              Level {team.owner_level}
                                            </span>
                                          ) : 'N/A'}
                                        </span>
                                      </div>
                                      <div className="flex justify-between">
                                        <span className="text-sm text-gray-600">Created:</span>
                                        <span className="text-sm font-medium">{formatDate(team.createdAt)}</span>
                                      </div>
                                      <div className="flex justify-between">
                                        <span className="text-sm text-gray-600">Updated:</span>
                                        <span className="text-sm font-medium">{formatDate(team.updatedAt)}</span>
                                      </div>
                                    </div>
                                  </div>
                                  
                                  {/* Referral Card */}
                                  <div className="bg-indigo-50 p-4 rounded-lg border border-indigo-100">
                                    <h4 className="text-sm font-medium text-indigo-700 mb-3 flex items-center">
                                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-1 text-indigo-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
                                      </svg>
                                      Referral Information
                                    </h4>
                                    <div className="flex items-center mb-4 p-2 bg-white rounded-lg">
                                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-indigo-500 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4" />
                                      </svg>
                                      <div className="flex-1">
                                        <span className="text-sm font-medium">Referral Code:</span>
                                        <div className="flex items-center mt-1">
                                          <code className="bg-gray-100 px-2 py-1 rounded text-sm">{team.referalCode}</code>
                                          <button 
                                            className="ml-2 text-indigo-600 hover:text-indigo-800"
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              if (team.referalCode) {
                                                navigator.clipboard.writeText(team.referalCode);
                                              }
                                            }}
                                          >
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2" />
                                            </svg>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="flex items-start bg-white p-2 rounded-lg">
                                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-indigo-500 mr-2 mt-0.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                      </svg>
                                      <span className="text-sm">Share this code with new users to add them to your team. Each user who registers with this code becomes part of this team.</span>
                                    </div>
                                  </div>
                                </div>
                                
                                {/* Team Members */}
                                <div>
                                  <div className="flex items-center justify-between mb-3">
                                    <h4 className="text-md font-medium text-gray-900 flex items-center">
                                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-1 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
                                      </svg>
                                      Team Members
                                    </h4>
                                    <span className="text-sm text-gray-500">
                                      {teamMembers[team.owner_name] ? `${teamMembers[team.owner_name].length} members` : 'Loading...'}
                                    </span>
                                  </div>
                                  
                                  {!teamMembers[team.owner_name] ? (
                                    <div className="bg-white p-8 rounded-lg border border-gray-200 flex items-center justify-center">
                                      <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-indigo-500"></div>
                                    </div>
                                  ) : teamMembers[team.owner_name].length === 0 ? (
                                    <div className="bg-white p-8 rounded-lg border border-gray-200 text-center text-gray-500">
                                      No team members found
                                    </div>
                                  ) : (
                                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                                      {teamMembers[team.owner_name].map(member => (
                                        <div key={member.id} className="bg-white p-4 rounded-lg border border-gray-200 flex items-center">
                                          {member.avatar ? (
                                            <img 
                                              src={member.avatar} 
                                              alt={member.name || ''} 
                                              className="h-10 w-10 rounded-full mr-3"
                                            />
                                          ) : (
                                            <div 
                                              className="h-10 w-10 rounded-full flex items-center justify-center text-white font-bold mr-3"
                                              style={{ backgroundColor: stringToColor(member.name) }}
                                            >
                                              {member.name?.charAt(0).toUpperCase() || '?'}
                                            </div>
                                          )}
                                          <div>
                                            <div className="text-sm font-medium text-gray-900">{member.name}</div>
                                            <div className="flex items-center text-xs text-gray-500 mt-1">
                                              <span className="px-2 py-0.5 bg-green-100 text-green-800 rounded mr-2">
                                                Level {member.level || 1}
                                              </span>
                                              <span className="text-gray-400">•</span>

                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="p-8 text-center text-gray-500">
                No teams found matching your search
              </div>
            )}
          </div>
        ) : (
          <div className="bg-white rounded-xl shadow-md p-6">
            <h3 className="text-lg font-medium text-gray-900 mb-4">User Hierarchy</h3>
            
            {usersWithTeams.length === 0 ? (
              <div className="flex justify-center items-center h-64">
                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-500"></div>
              </div>
            ) : (
              <div className="space-y-4">
                {usersWithTeams.map(user => (
                  <div key={user.id} className="border border-gray-200 rounded-lg overflow-hidden">
                    <div className="p-4 bg-gray-50 flex items-center justify-between">
                      <div className="flex items-center">
                        {user.avatar ? (
                          <img 
                            src={user.avatar} 
                            alt={user.name || ''} 
                            className="h-10 w-10 rounded-full mr-3"
                          />
                        ) : (
                          <div 
                            className="h-10 w-10 rounded-full flex items-center justify-center text-white font-bold mr-3"
                            style={{ backgroundColor: stringToColor(user.name) }}
                          >
                            {user.name?.charAt(0).toUpperCase() || '?'}
                          </div>
                        )}
                        <div>
                          <div className="text-sm font-medium text-gray-900">{user.name}</div>
                          <div className="text-xs text-gray-500">{user.email}</div>
                        </div>
                      </div>
                      <div className="flex items-center space-x-3">
                        {user.team_name && (
                          <span className="px-3 py-1 bg-blue-100 text-blue-800 rounded-full text-xs font-medium">
                            Team: {user.team_name}
                          </span>
                        )}
                        <span className="px-3 py-1 bg-green-100 text-green-800 rounded-full text-xs font-medium">
                          Level {user.level || 1}
                        </span>
                      </div>
                    </div>
                    
                    {user.children && user.children.length > 0 && (
                      <div className="p-4 border-t border-gray-200">
                        <h4 className="text-xs font-medium text-gray-500 uppercase tracking-wider mb-3">Referred Members</h4>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
                          {user.children.map(child => (
                            <div key={child.id} className="flex items-center p-2 bg-gray-50 rounded-lg">
                              {child.avatar ? (
                                <img 
                                  src={child.avatar} 
                                  alt={child.name || ''} 
                                  className="h-8 w-8 rounded-full mr-2"
                                />
                              ) : (
                                <div 
                                  className="h-8 w-8 rounded-full flex items-center justify-center text-white font-bold mr-2"
                                  style={{ backgroundColor: stringToColor(child.name) }}
                                >
                                  {child.name?.charAt(0).toUpperCase() || '?'}
                                </div>
                              )}
                              <div className="flex-1">
                                <div className="text-sm font-medium">{child.name}</div>
                                <div className="text-xs text-gray-500">Level {child.level || 1}</div>
                              </div>
                              {child.team_name && (
                                <span className="px-2 py-0.5 bg-blue-100 text-blue-800 rounded text-xs">
                                  {child.team_name}
                                </span>
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
      
      {/* <style jsx>{`
        @keyframes fadeIn {
          from { opacity: 0; transform: translateY(-10px); }
          to { opacity: 1; transform: translateY(0); }
        }
        .animate-fadeIn {
          animation: fadeIn 0.3s ease-out forwards;
        }
      `}</style> */}
    </div>
  );
};

export default Referal;