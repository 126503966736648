// import React, { useState, useEffect } from 'react';
// import { Outlet, useLocation } from 'react-router-dom';
// import Sidebar from './Sidebar';

// const Layout = () => {
//     const location = useLocation();
//     const [isSidebarOpen, setIsSidebarOpen] = useState(true);
//     const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
//     const [marginLeft, setMarginLeft] = useState('0');
//     const sidebarWidth = 256; // 64 * 4 = 256px (w-64 in Tailwind)
//     const mobileSidebarWidth = 192; // 48 * 4 = 192px (w-48 in Tailwind)

//     useEffect(() => {
//         const handleResize = () => {
//             const newIsMobile = window.innerWidth < 768;
//             setIsMobile(newIsMobile);
//             updateMarginLeft(newIsMobile);
//         };

//         window.addEventListener('resize', handleResize);
//         return () => window.removeEventListener('resize', handleResize);
//     }, []);

//     useEffect(() => {
//         updateMarginLeft(isMobile);
//     }, [location, isSidebarOpen, isMobile]);

//     const updateMarginLeft = (mobile:any) => {
//         if (isSidebarOpen) {
//             setMarginLeft(mobile ? '0' : `${sidebarWidth}px`);
//         } else {
//             setMarginLeft('0');
//         }
//     };

//     const getTopBarTitle = (pathname:string) => {
//         switch (pathname) {
//             case '/':
//                 return 'Dashboard';
//             case '/users':
//                 return 'Users';
//             case '/deposit':
//                 return 'Deposits';
//             case '/house':
//                 return 'House Edge';
//             case '/withdraw':
//                 return 'Withdraw';
//             case '/wallet':
//                 return 'User Wallet';
//             case '/history':
//                 return 'History';
//             case '/bonus':
//                     return 'Bonus';
//             case '/club':
//                      return 'Club Membership';
//              case '/siteconfig':
//                      return 'Site Config';
//             case '/banner':
//                     return 'Asset Managment';
//             case '/bankdetails':
//                     return 'Bank Details';
//             case '/sportsbeting':
//                         return 'Sports Bets';
//             case '/redeemcode':
//                             return 'Redeem Code Creation';
//             case '/exchangerate':
//                 return 'Exchange Rate';
//             case '/notification':
//                 return 'User Notification';
//             case '/swaphistory':
//                     return 'Swap History';
//             case '/affiliate':
//                 return 'Affiliate';
//             default:
//                 return 'App';
//         }
//     };

//     return (
//         <div className='flex h-screen bg-gray-100 overflow-hidden'>
//         <Sidebar isOpen={isSidebarOpen} toggleSidebar={() => setIsSidebarOpen(!isSidebarOpen)} />
//         <div className='flex-1 flex flex-col overflow-hidden'>
//             <div className="bg-[#37384A] text-white text-2xl font-semibold p-4 sticky top-0 z-10 text-center">
//                 {getTopBarTitle(location.pathname)}
//             </div>
//             <div 
//                 style={{ 
//                     // width: `calc(100% - ${isSidebarOpen ? (isMobile ? mobileSidebarWidth : sidebarWidth) : 0}px)`,
//                     // marginLeft: marginLeft,
//                     // transition: 'margin-left 300ms, width 300ms',
//                 }} 
//                 className='flex-1 overflow-auto bg-[#161B2B] p-4 md:p-10'
//             >
//                 <Outlet />
//             </div>
//         </div>
//     </div>
//     );
// };

// export default Layout;

import React, { useState, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Sidebar from './Sidebar';

const Layout = () => {
    const location = useLocation();
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 1024);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (isMobile) {
            setIsSidebarOpen(false);
        }
    }, [isMobile]);

    const getTopBarTitle = (pathname: string) => {
        const routes: Record<string, string> = {
            '/': 'Dashboard',
            '/users': 'Users',
            '/deposit': 'Deposits',
            '/house': 'House Edge',
            '/withdraw': 'Withdraw',
            '/wallet': 'User Wallet',
            '/history': 'History',
            '/bonus': 'Bonus',
            '/club': 'Club Membership',
            '/siteconfig': 'Site Config',
            '/banner': 'Asset Management',
            '/bankdetails': 'Bank Details',
            '/sportsbeting': 'Sports Bets',
            '/redeemcode': 'Redeem Code Creation',
            '/exchangerate': 'Exchange Rate',
            '/notification': 'User Notification',
            '/swaphistory': 'Swap History',
            '/affiliate': 'Affiliate'
        };
        
        return routes[pathname] || 'Dashboard';
    };

    return (
        <div className="flex h-screen bg-[#0F1525] overflow-hidden">
            <Sidebar isOpen={isSidebarOpen} toggleSidebar={() => setIsSidebarOpen(!isSidebarOpen)} />
            
            <div className="flex-1 flex flex-col overflow-hidden transition-all duration-300 ease-in-out">
                <header className="bg-[#1A2033] border-b border-[#2D334A] shadow-md py-3 px-6 flex items-center justify-between sticky top-0 z-10">
                    <div className="flex items-center">
                        <button 
                            className="lg:hidden text-gray-300 hover:text-white mr-4"
                            onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                            </svg>
                        </button>
                        <h1 className="text-white text-xl font-medium">{getTopBarTitle(location.pathname)}</h1>
                    </div>
                    <div className="flex items-center space-x-4">
                        
                        <div className="h-8 w-8 rounded-full bg-[#646ECD] flex items-center justify-center text-white">
                            <span className="text-sm font-medium">A</span>
                        </div>
                    </div>
                </header>
                
                <main className="flex-1 overflow-auto bg-[#161B2B] p-4 md:p-6">
                    <div className="container mx-auto max-w-7xl">
                        <Outlet />
                    </div>
                </main>
            </div>
        </div>
    );
};

export default Layout;